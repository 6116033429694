<template>
  <p style="font-size: 20px">
    {{ updateText }}
  </p>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "index",
  computed: {
    ...mapGetters(["updateText"]),
  },
};
</script>

<style scoped></style>
